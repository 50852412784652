import isServer from "@/_base/isServer";
export default (user) => {
	if (isServer) {
		return;
	}
	window.insider_object = window.insider_object || {};
	if (user) {
		window.insider_object.user = {
			uuid: String(user.id),
			gender: user.gender === "0" ? "F" : "M",
			birthday: user.birthday,
			//has_transacted: true,
			//transaction_count: 2,
			gdpr_optin: true,
			name: user.name,
			//surname: "Doe",
			//username: "jdoe",
			email: user.email,
			email_optin: user.newspaper,
			phone_number: user.phone.replace(/0/, "+886"),
			sms_optin: user.sms,
			//whatsapp_optin: true,
			language: "zh_tw",
			//returning: true,
			//list_id: [1, 3, 5],
			custom: {
				line_uid: user.line_id ? user.line_id : undefined,
				user_level: user.level,
			},
		}
	}
}