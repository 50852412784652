import moment from "moment";
import loading from "@/components/Loading.vue";
import popup from "@/components/AdvertisementModal.vue";
// import customerService from "@/components/CustomerService.vue";
import {
	createRouter,
	createMemoryHistory,
	createWebHistory,
} from 'vue-router';
import isServer from "@/_base/isServer";
import setUserObject from "@/plugin/insider-user-object.js";


const history = isServer ? createMemoryHistory("") : createWebHistory("");
const createRoutes = (store, vue) => {
	return [
		{
			path: "/",
			redirect: { name: 'DiscountLogin' },
    		children: [
				{
					name: 'App',
					path: '/',
					meta: {
						requireCode: false
					},
					components: {
						loading,
						popup,
						// customerService,
						default: () => import('../layout/AppLayout.vue'),
					},
					children: [
						{
							name: 'Index',
							path: '/',
							component: () => import('../pages/Index.vue'),
						},
						{
							path: '/member',
							meta: { hideCustomerService: true },
							redirect: { name: 'Login' },
							component: () => import('../layout/MemberLayout.vue'),
							children: [
								{
									path: 'login',
									name: 'Login',
									component: () => import('../pages/member/Login.vue'),
									beforeEnter: (to, from, next) => {
										if (Object.keys(to.query).length) {
											next({
												name: "Login",
												state: {
													to: to.params.to,
													from: to.query.from,
													code: to.query.code,
												},
											});
										} else {
											next();
										}
									},
								},
								{
									path: 'signup',
									name: 'Signup',
									component: () => import('../pages/member/Signup.vue'),
								},
								{
									path: 'phoneVerify',
									name: 'PhoneVerify',
									component: () => import('../pages/member/PhoneVerify.vue'),
								},
								{
									path: 'info',
									name: 'MemberInfo',
									component: () => import('../pages/member/Info.vue'),
									beforeEnter: (to, from, next) => {
										if (Object.keys(to.query).length) {
											next({
												name: "MemberInfo",
												state: {
													from: to.query.from,
													code: to.query.code,
												},
											});
										} else {
											next();
										}
									},
								},
								{
									path: 'lineBind',
									name: 'LineBind',
									component: () => import('../pages/member/LineBind.vue'),
								},
							],
						},
						{
							path: '/password',
							meta: { hideCustomerService: true },
							redirect: { name: 'PasswordModify' },
							component: () => import('../layout/MemberLayout.vue'),
							children: [
								{
									path: 'modify',
									name: 'PasswordModify',
									component: () => import('../pages/member/PasswordModify.vue'),
								},
								{
									path: 'forget',
									name: 'PasswordForgot',
									component: () => import('../pages/member/PasswordForgot.vue'),
								},
								{
									path: 'reset',
									name: 'PasswordReset',
									component: () => import('../pages/member/PasswordReset.vue'),
								},
							],
						},
						{
							path: '/cart',
							meta: { hideCustomerService: true },
							redirect: { name: 'CartStep1' },
							component: () => import('../layout/CartLayout.vue'),
							children: [
								{
									path: 'step1',
									name: 'CartStep1',
									props: true,
									component: () => import('../pages/cart/Step1.vue'),
								},
								{
									path: 'step2',
									name: 'CartStep2',
									component: () => import('../pages/cart/Step2.vue'),
									beforeEnter: async (to, from, next) => {
										if (!store.state.headerStore.cartNum) {
											next({ name: "CartStep1" });
											return;
										} else if (store.state.headerStore.cartData?.optional) {
											for(const optional of Object.values(store.state.headerStore.cartData.optional)){
												let totalNum = 0;
												if(optional.selectedAward){
													totalNum = optional.selectedAward.reduce((sum, product) => sum + product.num, 0);
												}
												if (optional.match.length !== 0) {
													next({ name: "CartStep1" });
													return;
												}else if(optional.beSelecteds && optional.beSelecteds.max !== totalNum){
													next({ name: "CartStep1" });
													return;
												}
											}
											next();
										} else {
											next();
										}
									},
								},
								{
									path: 'success/:order',
									name: 'CartSuccess',
									component: () => import('../pages/cart/Success.vue'),
								},
							],
						},
						{
							path: '/order',
							meta: { hideCustomerService: true },
							redirect: { name: 'OrderList' },
							component: () => import('../layout/MemberLayout.vue'),
							children: [
								{
									path: 'list',
									name: 'OrderList',
									component: () => import('../pages/order/List.vue'),
								},
								{
									path: 'detail/:order',
									name: 'OrderDetail',
									component: () => import('../pages/order/Detail.vue'),
								},
								{
									path: 'ask/:order',
									name: 'OrderAsk',
									component: () => import('../pages/order/Ask.vue'),
								},
								{
									path: 'return/:order',
									name: 'OrderReturn',
									component: () => import('../pages/order/Return.vue'),
								},
								{
									path: 'returnProcess/:order',
									name: 'OrderReturnProcess',
									component: () => import('../pages/order/ReturnProcess.vue'),
								},
								{
									path: 'invoice/:order',
									name: 'OrderInvoice',
									component: () => import('../pages/order/Invoice.vue'),
								},
							],
						},
						{
							path: '/category/:cid',
							name: 'Category',
							component: () => import('../pages/Category.vue'),
						},
						{
							path: '/product/:productId',
							name: 'Product',
							component: () => import('../pages/Product.vue'),
						},
						{
							path: '/activity',
							name: 'Activity',
							component: () => import('../pages/Activity.vue'),
						},
						{
							path: '/optional/:optionalId',
							name: 'Optional',
							component: () => import('../pages/Optional.vue'),
						},
						{
							path: '/promotion/:promotionId',
							name: 'Promotion',
							component: () => import('../pages/Promotion.vue'),
						},
						{
							path: '/search/:query',
							name: 'Search',
							component: () => import('../pages/Search.vue'),
						},
						{
							path: '/faq/:qId?',
							name: 'FAQ',
							component: () => import('../pages/FAQ.vue'),
						},
						{
							path: '/contact',
							name: 'Contact',
							component: () => import('../pages/Contact.vue'),
						},
						{
							path: '/page/:pageName',
							name: 'Page',
							component: () => import('../pages/Page.vue'),
						},
						{
							path: '/:pathMatch(.*)*',
							name: 'NotFound',
							component: () => import('../pages/NotFound.vue'),
						},
					],
				},
				{
					path: '/discount-login',
					name: 'DiscountLogin',
					components: {
						default: () => import('../layout/AuthLayout.vue'),
					},
					meta: {
						requireCode: true
					}
				},
				{
					path: '/plusPayQRcode/:orderId',
					name: 'PlusPayQRcode',
					component: () => import('../pages/PlusPayQRcode.vue'),
				},
			]
		},
	];
}

const addSource = ({ source, type, storage }) => {
	let sourceObj = {
		date: moment().format("yyyy/MM/DD, HH:mm:ss"),
	}
	sourceObj[type] = source;
	let sourceArray = storage.getStorageSync("source");
	if (sourceArray && sourceArray.length) {
		storage.setStorageSync("source", [...sourceArray, sourceObj]);
	} else {
		storage.setStorageSync("source", [sourceObj]);
	}
}

export default function (store, vue) {
	const router = createRouter({
		routes: createRoutes(store, vue),
		history,
		scrollBehavior(to, from, savedPosition) {
			return new Promise(async (resolve, reject) => {
				if (to.name === "Category") {
					if (to.params.cid !== from.params.cid) {
						await store.dispatch("categoriesProductsStore/clearCategoriesList");
					}
					await store.dispatch("categoriesProductsStore/getCategoriesList", {
						axios: vue.config.globalProperties.axios,
						cid: to.params.cid,
					});
				}
				const scrollOPtions = {
					top: !to.hash || !isServer && !to.query.from && document.querySelector(to.hash)?.offsetTop > window.pageYOffset ? 0 : store.state.headerStore.headerHeight,
					behavior: "smooth",
				}
				if (savedPosition) {
					resolve(savedPosition);
				} else {
					resolve(to.hash ? Object.assign(scrollOPtions, { el: to.hash }) : scrollOPtions);
				}
			});
		},
	});

	const loggedInRoute = Object.freeze({
		Login: "MemberInfo",
		Signup: "MemberInfo",
		PasswordForgot: "PasswordModify",
		PasswordReset: "PasswordModify",
	});
	const loggedOutRoute = Object.freeze({
		CartStep2: "CartStep1",
		CartSuccess: "CartStep1",
		MemberInfo: "Login",
		OrderList: "Login",
		OrderDetail: "Login",
		OrderAsk: "Login",
		OrderReturn: "Login",
		OrderReturnProcess: "Login",
		PasswordModify: "Login",
	});
	const callApiFunc = async() => {
		const storage = vue.config.globalProperties.$storage;
		const axios = vue.config.globalProperties.axios;
		const userState = await store.dispatch("headerStore/getUserState", {
			axios,
			cartId: storage.getStorageSync("cartId"),
		});
		setUserObject(userState?.data?.user);
	}
	
	router.beforeEach(async(to, from, next) => {
		const storage = vue.config.globalProperties.$storage;
		if (to.meta.requireCode) {
			vue.config.globalProperties.$store.commit("headerStore/setDiscountIn", false);
			next();

		// 非discount-login頁面時驗證
		} else {
			console.log(storage);
			const hasDiscountParameter = storage.getStorageSync("discount");
			
			// 網址無企業識別碼query或localStorage沒有discount時導向discount-login
			if (!Object.keys(to.query).length && !hasDiscountParameter) {
				vue.config.globalProperties.$store.commit("headerStore/setDiscountIn", false);
				next({ name:'DiscountLogin' });
			
			// 網址有企業識別碼，進行驗證
			} else if (to.query.discount) {
				const discount = to.query.discount;
				const axios = vue.config.globalProperties.axios;
				const swal = vue.config.globalProperties.$swal;
				const orginalDiscount = storage.getStorageSync("discount");
				await axios.post("/api/discount/login", {
					code: discount,
				}, { loading: true, }).then(async(res) => {
					if (res.data.message === 'ok') {
						storage.setStorageSync("discount", discount);
						if (discount !== orginalDiscount) {
							storage.removeStorageSync("cartId");
							storage.removeStorageSync("bucket_id");
						}
						vue.config.globalProperties.$store.commit("headerStore/setDiscountIn", true);
						if (!isServer && store.state.headerStore.discountIn) {
							// console.log("use query enter");
							await callApiFunc();
							next(to.path);
						}
					} else {
						swal.fire({
							icon: "warning",
							title: "企業優惠活動已過期或代碼錯誤",
							allowOutsideClick: false,
							allowEscapeKey: false,
						});
						vue.config.globalProperties.$store.commit("headerStore/setDiscountIn", false);
						// console.log("expired code or error code");
						next({ name:'DiscountLogin' });
					}
				})
				
			// localStorage has discount, check discount and login status
			} else {
				const axios = vue.config.globalProperties.axios;
				const swal = vue.config.globalProperties.$swal;
				await axios.post("/api/discount/login", {
					code: hasDiscountParameter,
				}, { loading: true, }).then(async(res) => {
					if (res.data.message === 'ok') {
						vue.config.globalProperties.$store.commit("headerStore/setDiscountIn", true);
						if (!isServer && store.state.headerStore.discountIn) {
							await callApiFunc();
							if (to.name in loggedInRoute || to.name in loggedOutRoute) {
								if (to.name in loggedInRoute && store.state.headerStore.loggedIn) {
									next({ name: loggedInRoute[to.name] });
								} else if (to.name in loggedOutRoute && !store.state.headerStore.loggedIn) {
									next({ name: loggedOutRoute[to.name] });
								} else {
									next();
								}
							} else {
								next();
							}
						}
					} else {
						swal.fire({
							icon: "warning",
							title: "企業優惠活動已過期",
							allowOutsideClick: false,
							allowEscapeKey: false,
						});
						vue.config.globalProperties.$store.commit("headerStore/setDiscountIn", false);
						next({ name:'DiscountLogin' });
					}
				})
			}
		}
	});
	router.afterEach((to, from, failure) => {
		if (!isServer) {
			store.dispatch("pageLoadingStore/closeLoading");
			const storage = vue.config.globalProperties.$storage;
			if (to.query.r) {
				addSource({ source: Number(to.query.r), type: "redirect", storage });
			} else if (!to.query.from || !history.state.from) {
				if (to.query.from) {
					document.__defineGetter__("referrer", () => { return });
				}
				if (document.referrer && new URL(document.referrer).hostname !== location.hostname) {
					addSource({ source: document.referrer, type: "referrer", storage });
				}
			}
			document.__defineGetter__("referrer", () => { return });
		}
	});
	return router;
}
