const deviceModule = {
	namespaced: true,
	state: {
        deviceInfo: undefined,
	},
	getters: {
	},
	mutations: {
		setDeviceInfo(state, deviceInfo) {
			state.deviceInfo = deviceInfo;
		},
	},
	actions: {
	},
	modules: {
	}
}

export default deviceModule;